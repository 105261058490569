export function endpoints(url: string, apiVersion: string) {
  return {
    api:                              `${url}/${apiVersion}`,
    auth:                             `${url}/auth`,
    refresh:                          `${url}/auth/refresh`,
    forgottenPassword:                `${url}/auth/forgotten_password`,
    resetPassword:                    `${url}/auth/change_password`,
    logout:                           `${url}/${apiVersion}/refresh_tokens`,
    self:                             `${url}/${apiVersion}/_self`,
    companyUsers:                     `${url}/${apiVersion}/customers`,
    companyBranches:                  `${url}/${apiVersion}/branches`,
    jobTemplates:                     `${url}/${apiVersion}/job_templates`,
    job:                              `${url}/${apiVersion}/jobs`,
    users:                            `${url}/${apiVersion}/users`,
    jobPicture:                       `${url}/${apiVersion}/jobs/picture`,
    jobDuunitoriBanner:               `${url}/${apiVersion}/jobs/duunitori_banner`,
    locations:                        `${url}/${apiVersion}/locations`,
    jobCategories:                    `${url}/${apiVersion}/job_categories`,
    jobEmploymentTypes:               `${url}/${apiVersion}/job_types_of_employment`,
    jobEmploymentTypesAddition:       `${url}/${apiVersion}/job_types_of_employment_addition`,
    candidates:                       `${url}/${apiVersion}/candidates`,
    jobsOverview:                     `${url}/${apiVersion}/jobs/page/overview/company`,
    jobsApplicationOverview:          `${url}/${apiVersion}/jobs/page/customer_data`,
    jobsApplicationOverviewManager:   `${url}/${apiVersion}/jobs/page/enterprise_manager_data/company`,
    comments:                         `${url}/${apiVersion}/application_comments`,
    hiringStatuses:                   `${url}/${apiVersion}/application_hiring_statuses`,
    applications:                     `${url}/${apiVersion}/applications`,
    notes:                            `${url}/${apiVersion}/notes`,
    kpi:                              `${url}/${apiVersion}/kpi`,
    cv:                               `${url}/${apiVersion}/application_ask_for_cv_request`,
    candidateCv:                      `${url}/${apiVersion}/application_documents/context/upload_resume_by_candidate`,
    sms:                              `${url}/${apiVersion}/applications/sms`,
    applicationEmails:                `${url}/${apiVersion}/application_emails`,
    companies:                        `${url}/${apiVersion}/companies`,
    companyHomePage:                  `${url}/${apiVersion}/company_landing_pages`,
    companyWhyWorkWithUs:             `${url}/${apiVersion}/why_work_with_us`,
    universalJobs:                    `${url}/${apiVersion}/universal_jobs`,
    applicationsByHiringStatus:       `${url}/${apiVersion}/applications/hiring_status`,
    publishingPlatforms:              `${url}/${apiVersion}/job_platforms`,
    customers:                        `${url}/${apiVersion}/customers`,
    applicationsHiringStatusTrigger:  `${url}/${apiVersion}/application_hiring_status_triggers`,
    allTemplates:                     `${url}/${apiVersion}/templates`,
    smsTemplates:                     `${url}/${apiVersion}/sms_templates`,
    emailTemplates:                   `${url}/${apiVersion}/application_email_templates`,
    jobDrafts:                        `${url}/${apiVersion}/job_drafts`,
    supportEmailSubjects:             `${url}/${apiVersion}/support_email_subjects`,
    supportEmail:                     `${url}/${apiVersion}/support_emails`,
    staffingEmailSubjects:            `${url}/${apiVersion}/staffing_and_recruitment_email_subjects`,
    staffingEmail:                    `${url}/${apiVersion}/staffing_and_recruitment_emails`,
    psykometrika:                     `${url}/${apiVersion}/psykometrika`,
    emailResponses:                   `${url}/${apiVersion}/application_email_responses`,
    markEmailResponsesAsRead:         `${url}/${apiVersion}/application_email_responses/were_read_by_employee/batch`,
    enterprises:                      `${url}/${apiVersion}/enterprises`,
    enterpriseWhyWorkWithUs:          `${url}/${apiVersion}/enterprise_why_work_with_us`,
    enterpriseHomePage:               `${url}/${apiVersion}/enterprise_landing_pages`,
    enterpriseUsers:                  `${url}/${apiVersion}/enterprise_managers`,
    pinnedApplications:               `${url}/${apiVersion}/pinned_applications`,
    integration:                      `${url}/${apiVersion}/check_customer`,
    managers:                         `${url}/${apiVersion}/enterpriseManagers`,
    company:                          `${url}/${apiVersion}/company`,
    auth2fa_totp:                     `${url}/auth2fa_totp`,
    auth2fa_email:                    `${url}/auth2fa_email`,
    storage:                          `${url}/${apiVersion}/storage`,
    enterpriseIntroText:              `${url}/${apiVersion}/enterprise_intros`,
    companyIntroText:                 `${url}/${apiVersion}/company_intros`,
    ssnAndFullNameRequests:           `${url}/${apiVersion}/application_ask_for_ssn_and_full_name_requests`,
    bindJobs:                         `${url}/${apiVersion}/bind_jobs`,
    applicationTags:                  `${url}/${apiVersion}/application_tags`,
    videoInterviews:                  `${url}/${apiVersion}/video_interviews`,
    videoInterview:                   `${url}/${apiVersion}/video_interview`,
    groupVideoInterview:              `${url}/${apiVersion}/group_video_interview`,
    groupVideoInterviews:             `${url}/${apiVersion}/group_video_interviews`,
    customerInGroupInterview:         `${url}/${apiVersion}/customer_in_group_video_interview`,
    enterpriseManagerInGroupInterview:`${url}/${apiVersion}/enterprise_manager_in_group_video_interview`,
    candidateInGroupInterview:        `${url}/${apiVersion}/candidate_in_group_video_interview`,
    thirdPartyInGroupInterview:       `${url}/${apiVersion}/third_party_in_group_video_interview`,
    applicationsPage:                 `${url}/${apiVersion}/applications/page`, // delete
    agreementForms:                   `${url}/${apiVersion}/agreement_forms`,
    agreements:                       `${url}/${apiVersion}/application_agreements`
  };
}
